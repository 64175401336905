import React, { useState} from 'react';
// Sections
import TopNavbar from "../components/Nav/TopNavbar";
import Header from "../components/Sections/Header";
import Services from "../components/Sections/Services";
import Projects from "../components/Sections/Projects";
import Blog from "../components/Sections/Blog";
import Pricing from "../components/Sections/Pricing";
import Contact from "../components/Sections/Contact";
import Footer from "../components/Sections/Footer"

export default function Landing() {
  let languageStoredInLocalStorage = localStorage.getItem("language");
  let [language, setLanguage] = useState(
    languageStoredInLocalStorage ? languageStoredInLocalStorage : "English"  );

  return (
    <>
      <TopNavbar   language={language}
        handleSetLanguage={language => {
          setLanguage(language);
          storeLanguageInLocalStorage(language);
        }}/>
      <Header />
      <Services />
      {/* <Projects /> */}
      {/* <Blog /> */}
      {/* <Pricing />
      <Contact /> */}
      <Footer />
    </>
  );
}
function storeLanguageInLocalStorage(language) {
  localStorage.setItem("language", language);
}



