import React from "react";
import styled from "styled-components";
import { Link } from "react-scroll";
// Assets
import LogoIcon from "../../assets/svg/logo_ff_white.png";

export default function Contact() {

  const getCurrentYear = () => {
    return new Date().getFullYear();
  }

  let content = {
    English: {
      rights: "All Right Reserved",
      backtotop: "Back to top",
      companydata_line_1: "Share capital: 20,000.00kn paid in full",
      companydata_line_2: "Chief Executive Officer: Goran David",
      companydata_line_3: "Headquarters: Krištanovec 106/2, 40000 Čakovec, Croatia ",
      companydata_line_4: "Jadranski Trg 3a, 51000 Rijeka, Republic of Croatia "

    },
    Croatian: {
      rights: "Sva prava zadržana",
      backtotop: "Natrag na vrh stranice",
      companydata_line_1: "Temeljni kapital: 20,000.00kn uplaćen u cijelosti",
      companydata_line_2: "Direktor: Goran David",
      companydata_line_3: "Sjedište: Krištanovec 106/2, 40000 Čakovec, Hrvatska ",
      companydata_line_4: "Jadranski Trg 3a, 51000 Rijeka, Republika Hrvatska "

    }
  };
  let languageStoredInLocalStorage = localStorage.getItem("language"); //fetch saved language

  switch (languageStoredInLocalStorage) {
    case 'Croatian':
      content = content.Croatian;
      break;
    default:
      content = content.English;
  }


  return (
    <Wrapper>
      <div className="darkBg">
        <div className="container">
          <InnerWrapper className="flexSpaceCenter" style={{ padding: "30px 0" }}>
            <Link className="flexCenter animate pointer" to="home" smooth={true} offset={-80}>
            <img  width="250" height="52" src={LogoIcon} />
            </Link>
            <StyleP className="whiteColor font13">
              © {getCurrentYear()} - <span className="purpleColor font13">ForgeFuse</span> {content.rights}
            </StyleP>
            <StyleP className="whiteColor font13">
             info@forgefuse.net <br></br> 
             ForgeFuse društvo s ograničenom odgovornošću za istraživanje, razvoj i informatičke usluge<br></br>
             ForgeFuse d.o.o.  <br></br> 
             OIB: 82543548330  <br></br> 
             Trgovački sud u Varaždinu, MBS:070190207  <br></br> 
             {content.companydata_line_1}  <br></br> 
             ERSTE&STEIERMÄRKISCHE BANK d.d.<br></br>  {content.companydata_line_4} <br></br> 
             SWIFT/BIC : ESBCHR22<br></br>
             IBAN: HR1324020061101076961  <br></br> 
             {content.companydata_line_2}  <br></br> 
             {content.companydata_line_3} <br></br> 
            </StyleP>
            <Link className="whiteColor animate pointer font13" to="home" smooth={true} offset={-80}>
              {content.backtotop}
            </Link>
          </InnerWrapper>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100%;
`;
const InnerWrapper = styled.div`
  @media (max-width: 550px) {
    flex-direction: column;
  }
`;
const StyleP = styled.p`
  @media (max-width: 550px) {
    margin: 20px 0;
  }
`;