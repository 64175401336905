import React from "react";
import styled from "styled-components";
// Components
import ClientSlider from "../Elements/ClientSlider";
import ServiceBox from "../Elements/ServiceBox";
import FullButton from "../Buttons/FullButton";
// Assets
import AddImage1 from "../../assets/img/add/1.png";
import AddImage2 from "../../assets/img/add/2.png";
import AddImage3 from "../../assets/img/add/3.png";
import AddImage4 from "../../assets/img/add/4.png";

export default function Services() {
  let content = {
    English: {
      services: "Our Services",
      servicestext_1: "We offer wide variety of IT services, even if the wanted service is not listed on the web page",
      servicestext_2: "contact us with confidence for potential business cooperation.",
      service_1_title: "Graphic Design",
      service_1_text: "You need a logo for your company, design of promo materials, video editing, onsite photographing or any related service, we are the right choice.",
      service_2_title: "Web Design",
      service_2_text: "You need simple landing page or even complex custom CMS, we can arrange any of that, even without any monthly hosting or domain costs.",
      service_3_title: "Oracle",
      service_3_text: "Either you need development on databases, custom Oracle EBS modules, support on your systems, custom Apex application, we have the certification and experience for it.",
      service_4_title: "Mobile",
      service_4_text: "If your clients use iOS or Android phones, it's not a problem, we can write native or Xamarin code to support both platforms.",
      about_company_title: "Contact Us!",
      about_company_subtitle: "Extensive Experience and Performance",
      about_company_text: "You can contact us for any IT or design question, regardless if you're client or searching for B2B endevours, from the smallest tasks up to the big longterm cooperations.",
      contact_us:"Contact Us!"


    },
    Croatian: {
      services: "Naša Ponuda",
      servicestext_1: "U ponudi imamo široki spektar IT usluga, čak i ukoliko tražena usluga nije uvrštena na web stranici",
      servicestext_2: "obratite nam se s povjerenjem za potencijalnu poslovnu suradnju.",
      service_1_title: "Grafički Dizajn",
      service_1_text: "Trebate logotip za Vašu tvrtku, dizajn promo materijala, obradu videa, fotografiranje na licu mjesta ili bilo koju drugu vezanu uslugu, mi smo pravi izbor.",
      service_2_title: "Web Dizajn",
      service_2_text: "Trebate jednostavnu web stranicu ili kompleksni prilagođen CMS, možemo omogućiti bilo koje od toga, čak bez mjesečnih troškova domene ili hostinga.",
      service_3_title: "Oracle",
      service_3_text: "Bilo da trebate razvoj na bazama podataka, prilagođenim EBS modulima, podršku Vašim rješenjima, prilagođene Apex aplikacije, mi imamo certifikaciju i iskustvo za to.",
      service_4_title: "Mobile",
      service_4_text: "If your clients use iOS or Android phones, it's not a problem, we can write native or Xamarin code to support both platforms.",
      about_company_title: "Kontaktirajte nas!",
      about_company_subtitle: "Opsežno Iskustvo i Izvedba ",
      about_company_text: "Možete nas kontaktirati za bilo koje IT ili dizajnersko pitanje, bez obzira jeste li klijent ili tražite B2B poduhvat, bilo za male zadatke ili dugotrajne projekte i kooperacije.",
      contact_us:"Kontaktirajte nas!"   
    }
  };
  let languageStoredInLocalStorage = localStorage.getItem("language"); //fetch saved language

  switch (languageStoredInLocalStorage) {
    case 'Croatian':
      content = content.Croatian;
      break;
    default:
      content = content.English;
  }

  return (
    <Wrapper id="services">
      <div className="lightBg" style={{ padding: "50px 0" }}>
        <div className="container">
          <ClientSlider />
        </div>
      </div>
      <div className="whiteBg" style={{ padding: "60px 0" }}>
        <div className="container">
          <HeaderInfo>
            <h1 className="font40 extraBold">{content.services}</h1>
            <p className="font13">
              {content.servicestext_1}
              <br />
              {content.servicestext_2}
            </p>
          </HeaderInfo>
          <ServiceBoxRow className="flex">
            <ServiceBoxWrapper>
              <ServiceBox
                icon="roller"
                title={content.service_1_title}
                subtitle={content.service_1_text}
              />
            </ServiceBoxWrapper>
            <ServiceBoxWrapper>
              <ServiceBox
                icon="monitor"
                title={content.service_2_title}
                subtitle={content.service_2_text}
              />
            </ServiceBoxWrapper>
            <ServiceBoxWrapper>
              <ServiceBox
                icon="browser"
                title={content.service_3_title}
                subtitle={content.service_3_text}
              />
            </ServiceBoxWrapper>
            <ServiceBoxWrapper>
              <ServiceBox icon="printer"                 
                title={content.service_4_title}
                subtitle={content.service_4_text}
              />
            </ServiceBoxWrapper>
          </ServiceBoxRow>
        </div>
        <div className="lightBg" id="contact">
          <div className="container">
            <Advertising className="flexSpaceCenter">
              <AddLeft>
                <h4 className="font15 semiBold">{content.about_company_subtitle}</h4>
                <h2 className="font40 extraBold">{content.about_company_title}</h2>
                <p className="font12">
                {content.about_company_text}
                </p>
                <ButtonsRow className="flexNullCenter" style={{ margin: "30px 0"}}>
                  {/* <div style={{ width: "190px" }}>
                    <FullButton title="Get Started" action={() => alert("clicked")} />
                  </div> */}
                  <div style={{ width: "190px", marginLeft: "15px" }}>
                  <a href="mailto:info@forgefuse.net">
                    <FullButton title={content.contact_us} border /></a>
                  </div>
                </ButtonsRow>
              </AddLeft>
              <AddRight>
                <AddRightInner>
                  <div className="flexNullCenter">
                    <AddImgWrapp1 className="flexCenter">
                      <img src={AddImage1} alt="office" />
                    </AddImgWrapp1>
                    <AddImgWrapp2>
                      <img src={AddImage2} alt="office" />
                    </AddImgWrapp2>
                  </div>
                  <div className="flexNullCenter">
                    <AddImgWrapp3>
                      <img src={AddImage3} alt="office" />
                    </AddImgWrapp3>
                    <AddImgWrapp4>
                      <img src={AddImage4} alt="office" />
                    </AddImgWrapp4>
                  </div>
                </AddRightInner>
              </AddRight>
            </Advertising>
          </div>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
`;
const ServiceBoxRow = styled.div`
  @media (max-width: 860px) {
    flex-direction: column;
  }
`;
const ServiceBoxWrapper = styled.div`
  width: 20%;
  margin-right: 5%;
  padding: 80px 0;
  @media (max-width: 860px) {
    width: 100%;
    text-align: center;
    padding: 40px 0;
  }
`;
const HeaderInfo = styled.div`
  @media (max-width: 860px) {
    text-align: center;
  }
`;
const Advertising = styled.div`
  margin: 80px 0;
  padding: 100px 0;
  position: relative;
  @media (max-width: 1160px) {
    padding: 100px 0 40px 0;
  }
  @media (max-width: 860px) {
    flex-direction: column;
    padding: 0 0 30px 0;
    margin: 80px 0 0px 0;
  }
`;
const ButtonsRow = styled.div`
  @media (max-width: 860px) {
    justify-content: space-between;
  }
`;
const AddLeft = styled.div`
  width: 50%;
  p {
    max-width: 475px;
  }
  @media (max-width: 860px) {
    width: 80%;
    order: 2;
    text-align: center;
    h2 {
      line-height: 3rem;
      margin: 15px 0;
    }
    p {
      margin: 0 auto;
    }
  }
`;
const AddRight = styled.div`
  width: 50%;
  position: absolute;
  top: -70px;
  right: 0;
  @media (max-width: 860px) {
    width: 80%;
    position: relative;
    order: 1;
    top: -40px;
  }
`;
const AddRightInner = styled.div`
  width: 100%;
`;
const AddImgWrapp1 = styled.div`
  width: 48%;
  margin: 0 6% 10px 6%;
  img {
    width: 100%;
    height: auto;
    border-radius: 1rem;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
  }
`;
const AddImgWrapp2 = styled.div`
  width: 30%;
  margin: 0 5% 10px 5%;
  img {
    width: 100%;
    height: auto;
    border-radius: 1rem;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
  }
`;
const AddImgWrapp3 = styled.div`
  width: 20%;
  margin-left: 40%;
  img {
    width: 100%;
    height: auto;
    border-radius: 1rem;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
  }
`;
const AddImgWrapp4 = styled.div`
  width: 30%;
  margin: 0 5%auto;
  img {
    width: 100%;
    height: auto;
    border-radius: 1rem;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
  }
`;