import React from "react";
import Slider from "react-slick";
import styled from "styled-components";
// Assets

import ClientLogoJava from "../../assets/img/clients/logo_java.png";
import ClientLogoOracle from "../../assets/img/clients/logo_oracle.png";
import ClientLogoReact from "../../assets/img/clients/logo_react.png";
import ClientLogoXamarin from "../../assets/img/clients/logo_xamarin.png";
import ClientLogoAdobe from "../../assets/img/clients/logo_adobe.png";
import ClientLogoJira from "../../assets/img/clients/logo_jira.png";
import ClientLogoMongoDB from "../../assets/img/clients/logo_mongodb.png";
import ClientLogoWeb3 from "../../assets/img/clients/logo_web3.png";
import ClientLogoUdemy from "../../assets/img/clients/logo_udemy.png";
import ClientLogoCsharp from "../../assets/img/clients/logo_csharp.png";



export default function ClientSlider() {
  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 6,
    slidesToScroll: 2,
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <div>
      <Slider {...settings}>
        <LogoWrapper className="flexCenter">
        <img  width="93" height="93" src={ClientLogoJava} />
        </LogoWrapper>
        <LogoWrapper className="flexCenter">
        <img  width="126" height="126" src={ClientLogoOracle} />
        </LogoWrapper>
        <LogoWrapper className="flexCenter">
        <img  width="90" height="90" src={ClientLogoReact} />
        </LogoWrapper>
        <LogoWrapper className="flexCenter">
        <img  width="100" height="100" src={ClientLogoXamarin} />
        </LogoWrapper>
        <LogoWrapper className="flexCenter">
        <img  width="100" height="100" src={ClientLogoAdobe} />
        </LogoWrapper>
        <LogoWrapper className="flexCenter">
        <img  width="100" height="100" src={ClientLogoJira} />
        </LogoWrapper>
        <LogoWrapper className="flexCenter">
        <img  width="100" height="100" src={ClientLogoMongoDB} />
        </LogoWrapper>
        <LogoWrapper className="flexCenter">
        <img  width="100" height="100" src={ClientLogoWeb3} />
        </LogoWrapper>
        <LogoWrapper className="flexCenter">
        <img  width="100" height="100" src={ClientLogoUdemy} />
        </LogoWrapper>
        <LogoWrapper className="flexCenter">
        <img  width="100" height="100" src={ClientLogoCsharp} />
        </LogoWrapper>
      </Slider>
    </div>
  );
}

const LogoWrapper = styled.div`
  width: 100%;
  height: 100px;
  cursor: pointer;
  :focus-visible {
    outline: none;
    border: 0px;
  }
`;
const ImgStyle = styled.img`
  width: 100%;
  height: 100%;
  padding: 10%;
`;